import React, { Component } from "react";
import { getAqiDevices } from "../../shared/services/events";
import { HashLink as Link } from "react-router-hash-link";
import MinMaxBar from "../graphs/barGraph";
class AqiTableForGraph extends Component {
  constructor(props) {
    super(props);

    this.state = {
      filterDevices: [],
    };
  }
  componentWillMount() {
      if (
        this.props.aqiDevices &&
        this.props.dustDevices
      ){
        let filterDevices = [],
            offlineDevies = []
        this.props.aqiDevices.map((device, index) =>
          this.props.dustDevices.map((d) =>{
            if(device.hubId === d.hubId && device.dust === d.event.deviceId){
              if(!(new Date(device.event.created) <= new Date(new Date() - (1000 * 60 * 60)))){
                filterDevices.push({
                  active: true,
                  collapse: true,
                  _id: device._id,
                  location:device.location,
                  name:device.level[0].name,
                  created:device.event.created,
                  dust:d.event.value.toFixed(0),
                  aqi:device.event.value,
                  coordinate: device.level[0].metadata.coordinates
                })
              }else{
                offlineDevies.push({
                  active: false,
                  collapse: true,
                  _id: device._id,
                  location:device.location,
                  name:device.level[0].name,
                  created:device.event.created,
                  dust:d.event.value.toFixed(0),
                  aqi:device.event.value,
                  coordinate: device.level[0].metadata.coordinates
                })
              }
            }
          })
        )
        filterDevices = [...filterDevices,...offlineDevies]
        this.setState({
          filterDevices,
        });
      }
  }

  tableColor = (value) => {
    let Colorvalue = parseInt(value),
      tableColor = "",
      red = 0,
      green = 255,
      blue = 0;
    if (Colorvalue > 25 && Colorvalue <= 50) {
      var per = ((Colorvalue - 25) / 25) * 100;
      red = (255 * per) / 100;
      green = 255;
      blue = 0;
    } else if (Colorvalue > 50 && Colorvalue <= 100) {
      var per = ((Colorvalue - 50) / 50) * 100;
      red = 255;
      green = 255 - (102 * per) / 100;
      blue = 0;
    } else if (Colorvalue > 100 && Colorvalue <= 200) {
      red = 255;
      green = 102 - (102 * (Colorvalue - 100)) / 100;
      blue = 0;
    } else if (Colorvalue > 200 && Colorvalue <= 300) {
      red = 200 - (55 * (Colorvalue - 200)) / 100;
      green = 0;
      blue = (255 * (Colorvalue - 225)) / 100;
    } else if (Colorvalue > 300) {
      red = 145;
      green = 0;
      blue = 191;
    }
    // else{
    //     red = 0;
    //     green = 0;
    //     blue = 0;
    // }
    tableColor = "rgb(" + red + "," + green + "," + blue + ")";
    return tableColor;
  };

  render() {
    return (
      <MinMaxBar
        _id={this.state.filterDevices[0]._id}
        type="Device"
        title={this.state.filterDevices[0].name}
      />
    );
  }
}

export default AqiTableForGraph;
