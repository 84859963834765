import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import Maps from "./maps/maps";
import RequestInfo from "./strip/request-info";
import AqiTable from "./tables/aqiTable";
import { getAqiDevices } from "../shared/services/events";
// import { getAqiLevels } from "../shared/services/levels";
// import Description from "./aq-desc/description";
import DecriptionBelow from "./aq-desc/desc-below";
import Header from "../components/header/header";
// import Footer from "../components/footer";
import MinMaxBar from "./graphs/barGraph";
import Loader from "../components/loader1";
import _ from "lodash";
import { Row, Col } from "reactstrap";
import AqiTableForGraph from "./tables/aqiTableForGraph";

let levelId = "",
  locationTitleCase = "",
  mapCoordinates;

class AqGraph extends Component {
  constructor(props) {
    super(props);

    this.state = {
      aqiDevices: null,
      dustDevices: null,
      filterHub: null,
      coordinate: { lat: 30.2919945, lng: 70.305532 },
      indivAQI: null
    };
    levelId = "";
    locationTitleCase = "";
  }
  titleCase = (str) => {
    return str.toLowerCase().replace(/\b(\w)/g, (s) => s.toUpperCase());
  };
  componentDidMount() {
    var locationName = this.props.match.params.location;
    this.aqiLevelClick(locationName);
  }

  async aqiLevelClick(location) {
    this.setState({
      aqiDevices: null,
      dustDevices: null,
      filterHub: null,
    });
    if(location){
      locationTitleCase = this.titleCase(location);
    }
    document.getElementById("root_title").innerHTML =
      "Linked Things - " + locationTitleCase + " Air Quality";

    if (location === "karachi") {
      mapCoordinates = { lat: 24.891465, lng: 67.081024 };
    } else if (location === "lahore") {
      mapCoordinates = { lat: 31.4979993, lng: 74.3397544 };
    } else if (location === "islamabad") {
      mapCoordinates = { lat: 33.621155, lng: 72.995002 };
      // locationTitleCase = this.titleCase("Peshawar");
    } else if (location === "peshawar") {
      mapCoordinates = { lat: 34.015802, lng: 71.589388 };
      // locationTitleCase = this.titleCase("Islamabad");
    }else{
      mapCoordinates = { lat: 30.2919945, lng: 70.305532 };
    }
    let aqiDevices1 = await getAqiDevices(
      "Karachi",
      "AQI"
    );
    let aqiDevices2 = await getAqiDevices(
      "Lahore",
      "AQI"
    );
    let aqiDevices3 = await getAqiDevices(
      "Islamabad",
      "AQI"
    );
    let aqiDevices4 = await getAqiDevices(
      "Peshawar",
      "AQI"
    );
    let indivAQI = {Karachi:aqiDevices1, Lahore:aqiDevices2, Islamabad:aqiDevices3, Peshawar:aqiDevices4}
    let aqiDevices = [...aqiDevices1, ...aqiDevices2, ...aqiDevices3, ...aqiDevices4]
    let dustDevicesArr = [];
    let aqiDevicesArr = [];

    let dustSplit = null;
    if (aqiDevices !== "error") {
      aqiDevices.sort((a, b) => (a.event.value < b.event.value ? 1 : -1));
      let dustDevices1 = await getAqiDevices(
        "Karachi",
        "Dust"
      );
      let dustDevices2 = await getAqiDevices(
        "Lahore",
        "Dust"
      );
      let dustDevices3 = await getAqiDevices(
        "Islamabad",
        "Dust"
      );
      let dustDevices4 = await getAqiDevices(
        "Peshawar",
        "Dust"
      );
      let dustDevices = [...dustDevices1, ...dustDevices2, ...dustDevices3, ...dustDevices4]
      aqiDevices.map((device) => {
        dustDevices.map((dust) => {
          if (device.hubId === dust.hubId) {
            dustSplit = dust.event.deviceId.split("_");
            if (dustSplit[2] == "Dust" || dustSplit[2] == "Dust2.5") {
              device["dust"] = dust.event.deviceId;
            }
          }
        });
      });
      aqiDevices.map((aqi) => {
        if (!_.get(aqi, "level[0].metadata.indoor")) {
          aqiDevicesArr.push(aqi);
        }
      });
      aqiDevices = aqiDevicesArr;
      dustDevices.map((dust) => {
        if (!_.get(dust, "level[0].metadata.indoor")) {
          dustDevicesArr.push(dust);
        }
      });
      dustDevices = dustDevicesArr;
      if (dustDevices !== "error") {
        this.setState({
          aqiDevices,
          dustDevices,
          indivAQI
        });
      }
    }
  }
  mapNavigation = (coord) => {
    this.setState({coordinate:coord})
  }
  render() {
    return (
      <div style={{height: '100vh'}}>
      {this.state.aqiDevices && this.state.dustDevices ? (
        <AqiTableForGraph
          aqiDevices={this.state.aqiDevices}
          dustDevices={this.state.dustDevices}
          mapNavigation={(e)=>this.mapNavigation(e)}
        />
      ) : (
        <Loader />
      )}
      </div>
    );
  }
}

export default withRouter(AqGraph);
