import {
  Map,
  InfoWindow,
  Marker,
  Polyline,
  GoogleApiWrapper,
} from "google-maps-react";
import { DefaultRenderer, MarkerClusterer } from "@googlemaps/markerclusterer";
import React, { Component } from "react";
import "react-bootstrap-range-slider/dist/react-bootstrap-range-slider.css";
import "../../scss/svgs.css";
import RangeSlider from "react-bootstrap-range-slider";
import * as functions from "../../functions";
import * as func from "../../functions/functions";
import $ from "jquery";
import * as moment from "moment";
import { connect } from "react-redux";
import { getAqiWithMedianByCity } from "../../shared/services/events";
import Slider_play from "../../images/slider/play.svg";
import Slider_pause from "../../images/slider/pause.svg";
import Slider_fwd from "../../images/slider/forward.svg";
import Slider_rwd from "../../images/slider/backward.svg";
import Loader from "../../components/loader1";
import {
  Badge,
  Button,
  ButtonDropdown,
  ButtonGroup,
  ButtonToolbar,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  CardTitle,
  Col,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Progress,
  Row,
  Table,
} from "reactstrap";
const cities = [
  "Karachi",
  "Lahore",
  "Islamabad",
  "Peshawar",
]
const cityLocations = {
  Karachi: { lat: 24.891465, lng: 67.081024 },
  Lahore:{ lat: 31.445429, lng: 74.2986164 },
  Islamabad: { lat: 33.621155, lng: 72.995002 },
  Peshawar: { lat: 33.979776, lng: 71.5016043 },
}
var markers= [
  {id:1, name: 'Dallas', value: Math.floor(Math.random() * (350 - 0 + 1) + 0), coordinates: {lat: 33.07178 , lng: -96.66011}},
  {id:2, name: 'Nashville', value: Math.floor(Math.random() * (350 - 0 + 1) + 0), coordinates: {lat: 36.37577  , lng: -86.88228}},
  {id:3, name: 'Pittsburgh', value: Math.floor(Math.random() * (350 - 0 + 1) + 0), coordinates: {lat: 40.63774 , lng: -79.89497}},
  {id:4, name: 'NewYork', value: Math.floor(Math.random() * (350 - 0 + 1) + 0), coordinates: {lat: 42.91502 , lng: -76.04976}},
]
var markers1= [
  // {id:1, name: 'Dallas', value: Math.floor(Math.random() * (350 - 0 + 1) + 0), coordinates: {lat: 33.07178 , lng: -96.66022}},
  // {id:1, name: 'Dallas', value: Math.floor(Math.random() * (350 - 0 + 1) + 0), coordinates: {lat: 33.07178 , lng: -96.66011}},
  // {id:1, name: 'Dallas', value: Math.floor(Math.random() * (350 - 0 + 1) + 0), coordinates: {lat: 33.07178 , lng: -96.66033}},
  // {id:1, name: 'Dallas', value: Math.floor(Math.random() * (350 - 0 + 1) + 0), coordinates: {lat: 33.07178 , lng: -96.66044}},
  {id:2, name: 'Nashville', value: Math.floor(Math.random() * (350 - 0 + 1) + 0), coordinates: {lat: 36.37577  , lng: -86.77418}},
  {id:2, name: 'Nashville', value: Math.floor(Math.random() * (350 - 0 + 1) + 0), coordinates: {lat: 36.36577  , lng: -86.88528}},
  {id:2, name: 'Nashville', value: Math.floor(Math.random() * (350 - 0 + 1) + 0), coordinates: {lat: 36.35577  , lng: -86.69938}},
  {id:2, name: 'Nashville', value: Math.floor(Math.random() * (350 - 0 + 1) + 0), coordinates: {lat: 36.33577  , lng: -86.55148}},
  // {id:3, name: 'Pittsburgh', value: Math.floor(Math.random() * (350 - 0 + 1) + 0), coordinates: {lat: 40.63774 , lng: -79.89417}},
  // {id:3, name: 'Pittsburgh', value: Math.floor(Math.random() * (350 - 0 + 1) + 0), coordinates: {lat: 40.63774 , lng: -79.89427}},
  // {id:3, name: 'Pittsburgh', value: Math.floor(Math.random() * (350 - 0 + 1) + 0), coordinates: {lat: 40.63774 , lng: -79.89447}},
  // {id:3, name: 'Pittsburgh', value: Math.floor(Math.random() * (350 - 0 + 1) + 0), coordinates: {lat: 40.63774 , lng: -79.89497}},
  // {id:4, name: 'NewYork', value: Math.floor(Math.random() * (350 - 0 + 1) + 0), coordinates: {lat: 42.91502 , lng: -76.04916}},
  // {id:4, name: 'NewYork', value: Math.floor(Math.random() * (350 - 0 + 1) + 0), coordinates: {lat: 42.91502 , lng: -76.04966}},
  // {id:4, name: 'NewYork', value: Math.floor(Math.random() * (350 - 0 + 1) + 0), coordinates: {lat: 42.91502 , lng: -76.04936}},
  // {id:4, name: 'NewYork', value: Math.floor(Math.random() * (350 - 0 + 1) + 0), coordinates: {lat: 42.91502 , lng: -76.04976}},
]
const triangleCoords = [
  {lat: 33.07178 , lng: -96.66011},
  {lat: 36.37577  , lng: -86.88228},
  {lat: 40.63774 , lng: -79.89497},
  {lat: 42.91502 , lng: -76.04976},
];
const triangleCoords1 = [
  {lat: 36.36577  , lng: -86.88528},
  {lat: 36.37577  , lng: -86.77418},
  {lat: 36.35577  , lng: -86.69938},
  {lat: 36.33577  , lng: -86.55148},
];
export class OverviewMap extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hourlyData: [],
      dailyData: [],
      hourlyDataMedian: [],
      dailyDataMedian: [],
      showMedian: true,
      radioSelected: 1,
      sliderValue: 29,
      showingInfoWindow: false,
      initialFetch: false,
      activeMarker: {},
      selectedPlace: {
        markerData: {
          name: null,
        },
      },
      visible: true,
      bounds: null,
      mobileView: false,
      center: {
        lat: null,
        lng: null,
      },
      data: null,
      google: null,
      devices: null,
      events: null,
      interval: null,
      filterHub: [],
      loading: true,
      playType: "Stop",
      sliderIndex: true,
      changeDateIndex: null,
    };
    this.fetchPlaces = this.fetchPlaces.bind(this);
  }

  onMarkerClick = (props, marker, e) => {
    if(this.state.showMedian){
      props.map.setCenter(props.position)
      props.map.setZoom(11)
      this.setState({
        showingInfoWindow: false,
        activeMarker: null,
      });
    }else{
      this.setState({
        // selectedPlace: props,
        // activeMarker: marker,
        // showingInfoWindow: true,
      });
    }
  };
  handleMouseOver = (props, marker, e) => {
    if (this.state.showingInfoWindow === false && this.state.mobileView === false) {
      this.setState({
        selectedPlace: props,
        activeMarker: marker,
        showingInfoWindow: true
      });
    }
  };
  handleMouseExit = (e) => {

    if (this.state.showingInfoWindow && this.state.mobileView === false) {
      this.setState({
        showingInfoWindow: false,
        activeMarker: null
      })
    }
  };
  onMapClicked = (props) => {
    if (this.state.showingInfoWindow) {
      this.setState({
        showingInfoWindow: false,
        activeMarker: null,
      });
    }
    if(this.map){
      this.map.setCenter({ lat: 37.858724, lng: -85.3013657 })
      this.map.setZoom(6)
    }
  };

  async componentDidMount() {
    this.setState({
      loading: true,
    });

    if (functions.isMobile.any() == null) {
      this.setState({
        mobileView: false,
      });
    } else {
      this.setState({
        mobileView: true,
      });
    }

    $(window).resize(() => {
      if (functions.isMobile.any() == null) {
        this.setState({
          mobileView: false,
        });
      } else {
        this.setState({
          mobileView: true,
        });
      }
    });

    let data = [];
    let mapDataIndiv = [];

    await Promise.all(
      cities.map(async city=>{
        let response = []
        // await getAqiWithMedianByCity(city,"hourly")
        response.map((events, eveIndex) => {
          let myEvent = events?events.reverse():[]
          if(eveIndex === 0){
            let a = this.props.indivAQI[city][0]
            mapDataIndiv.push({
              deviceId: a._id,
              levelId: a.levelId,
              events: myEvent,
              location: a.location,
            })
          }
          this.props.indivAQI[city].map(async (d) => {
            if(events[0].deviceId === d._id){
              data.push({
                deviceId: d._id,
                levelId: d.levelId,
                events: myEvent,
                location: d.location,
              })
            }
          })
        })
      })
    )

    let getHourlyData = this.getHourlyHistoryData(data)
    let hourlyData = getHourlyData.data;
    let hourlyIndex = getHourlyData.index;
    let getHourlyDataMedian = this.getHourlyHistoryData(mapDataIndiv, true)
    let hourlyDataMedian = getHourlyDataMedian.data;


    let data1 = [];
    let mapDataIndiv1 = [];

    await Promise.all(
      cities.map(async city=>{
        // let response = await getAqiWithMedianByCity(city,"daily")
        let response = []
        response.map((events, eveIndex) => {
          let myEvent = events?events.reverse():[]
          let a = this.props.indivAQI[city][0]
          if(eveIndex === 0){
            mapDataIndiv1.push({
              deviceId: a._id,
              levelId: a.levelId,
              events: myEvent,
            })
          }
          this.props.indivAQI[city].map(async (d) => {
            if(events[0].deviceId === d._id){
              data1.push({
                deviceId: d._id,
                levelId: d.levelId,
                events: myEvent,
              })
            }
          })
        })
      })
    )
    let dailyData = this.getDailyHistoryData(data1);
    let dailyDataMedian = this.getDailyHistoryData(mapDataIndiv1, true);

    this.setState({
      hourlyData,
      dailyData,
      hourlyDataMedian,
      dailyDataMedian,
      loading: false,
      sliderValue: hourlyIndex - 1,
      sliderIndex: false,
      changeDateIndex: hourlyIndex - 1,
    });
    this.mapInitialzation()
  }

  getHourlyHistoryData = (data, median = false) => {
    let hourlyData = [];
    let hourlyIndex = 0;
    let created;
    let change;
    let location;
    let deviceValues = [];
    if (data.length > 0) {
      // data.map((d,index)=>{
      //     d.events.map((e,inex1)=>{
      //         e.created = ( e.max.created > e.min.created ) ? e.max.created : e.min.created;
      //     })
      // })
      /**
       *  First we sort created record of 11 different devices events of same time
       *  & push it into hourlyData
       */

      var i;
      for (i = 0; i < 30; i++) {
        var j;
        for (j = 0; j < data.length; j++) {
          created = data[j].events[i].created;
          location = data[j].location;
          if (j == 0) {
            change = data[j].events[i].created;
            location = data[j].location;
          } else if (change < created) {
            change = data[j].events[i].created;
            location = data[j].location;
          }
        }
        hourlyData.push({
          created: change,
          deviceValues: [
            {
              deviceId: data[0].deviceId,
              levelId: data[0].levelId,
              value: median? data[0].events[i].median: data[0].events[i].avg,
              location: data[0].location,
            },
          ],
        });
      }

      hourlyData.map((h, index) => {
        data.map((d, index1) => {
          if (index1 > 0) {
            h.deviceValues.push({
              deviceId: d.deviceId,
              levelId: d.levelId,
              value: median? d.events[index].median: d.events[index].avg,
              location: d.location,
            });
          }
        });
      });

      let check2 = true;
      hourlyData.map((h, index) => {
        let propDate = hourlyData[index].created;
        let currDate = moment().format("YYYY-MM-DD[T]HH:mm:ss");
        /**
         * comparing dates to seperate historical data and predicted data
         */
        if (propDate > currDate && check2) {
          hourlyIndex = index;
          check2 = false;
        }
      });
    }
    return {data:hourlyData,index:hourlyIndex};
  }
  
  getDailyHistoryData = (data, median = false) => {
    let dailyData = [];
    if (data.length > 0) {
      data[0].events.map((d) => {
        dailyData.push({
          created: d.created,
          deviceValues: [
            {
              deviceId: data[0].deviceId,
              levelId: data[0].levelId,
              value: median? d.median: d.avg,
            },
          ],
        });
      });
      dailyData.map((h, index) => {
        data.map((d, index1) => {
          if (index1 > 0) {
            h.deviceValues.push({
              deviceId: d.deviceId,
              levelId: d.levelId,
              value: median? d.events[index].median: d.events[index].avg,
            });
          }
        });
      });
    }
    return dailyData
  }

  componentWillMount() {
    this.state.google = this.props.google;
    this.state.bounds = new this.props.google.maps.LatLngBounds();
    // this.setState({
    //     filterHub: this.props.filterHub
    // })

    // var points = [
    //     {
    //         lat: 24.861751,
    //         lng: 67.070533
    //     }
    // ]
    // this.props.aqiDevices.map((value) => {
    //   value.level[0].metadata
    //     ? this.state.bounds.extend(value.level[0].metadata.coordinates)
    //     : null;
    // });
  }
  componentWillReceiveProps(props) {
    if(this.map){
      this.map.setCenter(props.location)
      this.map.setZoom(12)
    }
  }
  // componentDidUpdate(){
  //   if(this.map){
  //     this.setMapMarker()
  //   }
  // }
  async fetchPlaces(mapProps, map) {
    this.map = map;
  }

  onRadioBtnClick(value) {

    this.setState(
      {
        sliderValue: value === 1 ? this.state.changeDateIndex : 14,
        radioSelected: value,
        playType: "Stop",
        sliderIndex: false,
      },
      () => {
        this.Play();
      }
    );
  }

  setMarker = (level,value = null) => {
    if (this.state.radioSelected === 1) {
      let data = []
      // if(this.state.showMedian){
      //   data = this.state.hourlyDataMedian
      // }else{
      //   data = this.state.hourlyData
      // }
      // if (data.length > 0) {

        let url = "";

        // let index = this.state.sliderValue;
        // if (!data[index]) {
        //   index = 29;
        // }
        let aqiValue = null
        if(value){
          aqiValue = value;
        }else{
          // data[index].deviceValues.map((d) => {
          //   if (level._id === d.levelId) {
          //     aqiValue = d.value;
          //   }
          // });
        }
        if (aqiValue !== -1) {
          let Colorvalue = parseInt(aqiValue),
            tableColor = "",
            red = 0,
            green = 255,
            blue = 0;
          if (Colorvalue > 25 && Colorvalue <= 50) {
            var per = ((Colorvalue - 25) / 25) * 100;
            red = (255 * per) / 100;
            green = 255;
            blue = 0;
          } else if (Colorvalue > 50 && Colorvalue <= 100) {
            var per = ((Colorvalue - 50) / 50) * 100;
            red = 255;
            green = 255 - (102 * per) / 100;
            blue = 0;
          } else if (Colorvalue > 100 && Colorvalue <= 200) {
            red = 255;
            green = 102 - (102 * (Colorvalue - 100)) / 100;
            blue = 0;
          } else if (Colorvalue > 200 && Colorvalue <= 300) {
            red = 200 - (55 * (Colorvalue - 200)) / 100;
            green = 0;
            blue = (255 * (Colorvalue - 225)) / 100;
          } else if (Colorvalue > 300) {
            red = 145;
            green = 0;
            blue = 191;
          }
          tableColor = "rgb(" + red + "," + green + "," + blue + ")";
          url = this.svgMarker(tableColor, aqiValue);
        } else {
          url = this.svgMarker("grey", "OFF");
        }
        return url;
      // } else {
      //   return this.svgMarker("#10ff00", "0");
      // }
    } else if (this.state.radioSelected === 2) {
      let data = []
      if(this.state.showMedian){
        data = this.state.dailyDataMedian
      }else{
        data = this.state.dailyData
      }
      if (data.length > 0) {
        let url = "";
        let index = this.state.sliderValue;
        if (!data[index]) {
          index = 14;
        }
        let aqiValue = null
        if(value){
          aqiValue = value;
        }else{
          data[index].deviceValues.map((d) => {
            if (level._id === d.levelId) {
              aqiValue = d.value;
            }
          });
        }
        if (aqiValue !== -1) {
          let Colorvalue = parseInt(aqiValue),
            tableColor = "",
            red = 0,
            green = 255,
            blue = 0;
          if (Colorvalue > 25 && Colorvalue <= 50) {
            var per = ((Colorvalue - 25) / 25) * 100;
            red = (255 * per) / 100;
            green = 255;
            blue = 0;
          } else if (Colorvalue > 50 && Colorvalue <= 100) {
            var per = ((Colorvalue - 50) / 50) * 100;
            red = 255;
            green = 255 - (102 * per) / 100;
            blue = 0;
          } else if (Colorvalue > 100 && Colorvalue <= 200) {
            red = 255;
            green = 102 - (102 * (Colorvalue - 100)) / 100;
            blue = 0;
          } else if (Colorvalue > 200 && Colorvalue <= 300) {
            red = 200 - (55 * (Colorvalue - 200)) / 100;
            green = 0;
            blue = (255 * (Colorvalue - 225)) / 100;
          } else if (Colorvalue > 300) {
            red = 145;
            green = 0;
            blue = 191;
          }
          tableColor = "rgb(" + red + "," + green + "," + blue + ")";
          url = this.svgMarker(tableColor, aqiValue);
        } else {
          url = this.svgMarker("grey", "OFF");
        }
        return url;
      } else {
        return this.svgMarker("#10ff00", "0");
      }
    }
  };

  svgMarker = (color, value, ) => {
    let fontSize;
    let online = true;
    if (value === "-1" || value === "OFF") {
      fontSize = "20px";
      online = false
    } else {
      fontSize = "20px";
    }
    return (
      '<svg xmlns="http://www.w3.org/2000/svg" xml:space="preserve" version="1.1" style="shape-rendering:geometricPrecision; text-rendering:geometricPrecision; image-rendering:optimizeQuality; fill-rule:evenodd; clip-rule:evenodd" viewBox="0 0 47.92 69.35" xmlns:xlink="http://www.w3.org/1999/xlink">'+
      '<defs><linearGradient id="marker_id0" gradientUnits="userSpaceOnUse" x1="-3.98" y1="34.83" x2="92.76" y2="34.85">'+
      '<stop offset="0" style="stop-opacity:1; stop-color:#0D3C60"/>'+
      '<stop offset="1" style="stop-opacity:1; stop-color:#1A72B7"/>'+
      '</linearGradient></defs>'+
      '<g>'+
      '<path fill="'+(!online?"grey":color)+'" d="M23.96 0c13.23,0 23.96,10.73 23.96,23.96 0,4.14 -1.05,8.03 -2.9,11.43l0 -0 -0.01 0.01c-0.43,0.8 -0.91,1.57 -1.43,2.31l-19.63 31.64 -19.63 -31.64c-0.52,-0.74 -1,-1.51 -1.43,-2.31l-0.01 -0.01 0 0c-1.85,-3.4 -2.9,-7.29 -2.9,-11.43 0,-13.23 10.73,-23.96 23.96,-23.96z"/>'+
      '<circle fill="white" cx="23.96" cy="23.96" r="21.34"/>'+
      '<text dominant-baseline="middle" text-anchor="middle" x="50%" y="37%" fill="black" style="font-weight:bold;font-size:' +
          fontSize +';font-family:Arial">' +
          (online?value:"OFF") +
      "</text>" +
      '</g></svg>'
    );
  };

  Play = () => {
    if (this.state.radioSelected === 1) {
      var mySlider;
      let val = 1;
      if (this.state.sliderValue == 29) {
        val = 0;
      }
      let propDate =
        this.state.hourlyData[parseInt(this.state.sliderValue) + val].created;
      let currDate = moment().format("YYYY-MM-DD[T]HH:mm:ss");
      let sliderIndex = true;
      if (propDate < currDate) {
        sliderIndex = false;
      } else {
        sliderIndex = true;
      }

      if (this.state.playType === "Play") {
        mySlider = setTimeout(() => {
          if (this.state.sliderValue < 29) {
            this.setState(
              {
                sliderValue: parseInt(this.state.sliderValue) + 1,
                sliderIndex,
              },
              () => {
                this.Play();
              }
            );
          } else {
            this.setState({
              playType: "Stop",
            });
          }
        }, 2000);
      }
      if (this.state.playType === "Stop") {
        clearTimeout(mySlider);
      }
    } else if (this.state.radioSelected === 2) {
      var mySlider;

      if (this.state.playType === "Play") {
        mySlider = setTimeout(() => {
          if (this.state.sliderValue < 14) {
            this.setState(
              {
                sliderValue: parseInt(this.state.sliderValue) + 1,
              },
              () => {
                this.Play();
              }
            );
          } else {
            this.setState({
              playType: "Stop",
            });
          }
        }, 2000);
      }
      if (this.state.playType === "Stop") {
        clearTimeout(mySlider);
      }
    }
  };

  handlePlay = () => {
    if (this.state.radioSelected === 1) {
      if (this.state.playType === "Play") {
        this.setState({ playType: "Stop" }, () => {
          this.Play();
        });
      } else if (this.state.playType === "Stop") {
        this.setState(
          { playType: "Play", sliderValue: 0, sliderIndex: false },
          () => {
            this.Play();
          }
        );
      }
    } else if (this.state.radioSelected === 2) {
      if (this.state.playType === "Play") {
        this.setState({ playType: "Stop" }, () => {
          this.Play();
        });
      } else if (this.state.playType === "Stop") {
        this.setState({ playType: "Play", sliderValue: 0 }, () => {
          this.Play();
        });
      }
    }
  };

  handleRwd = () => {
    if (this.state.sliderValue > 0) {
      let propDate =
        this.state.hourlyData[parseInt(this.state.sliderValue) - 1].created;
      let currDate = moment().format("YYYY-MM-DD[T]HH:mm:ss");

      let sliderIndex = true;
      if (propDate < currDate) {
        sliderIndex = false;
      } else {
        sliderIndex = true;
      }

      this.setState({
        sliderValue: parseInt(this.state.sliderValue) - 1,
        sliderIndex,
      });
    }
  };

  handleFwd = () => {
    if (this.state.radioSelected === 1) {
      if (this.state.sliderValue < 29) {
        let propDate =
          this.state.hourlyData[parseInt(this.state.sliderValue) + 1].created;
        let currDate = moment().format("YYYY-MM-DD[T]HH:mm:ss");

        let sliderIndex = true;
        if (propDate < currDate) {
          sliderIndex = false;
        } else {
          sliderIndex = true;
        }

        this.setState({
          sliderValue: parseInt(this.state.sliderValue) + 1,
          sliderIndex,
        });
      }
    } else if (this.state.radioSelected === 2) {
      if (this.state.sliderValue < 14) {
        this.setState({
          sliderValue: parseInt(this.state.sliderValue) + 1,
        });
      }
    }
  };
  getOnline = (level) => {
    let Online = false
    if(this.state.radioSelected === 1){
      let data = []
      if(this.state.showMedian){
        data = this.state.hourlyDataMedian
      }else{
        data = this.state.hourlyData
      }
      if (data.length > 0) {
        data[this.state.sliderValue].deviceValues.map( d=>{ 
          if(level._id === d.levelId){ 
            if(d.value >= 0){
              Online = true
            }else{
              Online = false
            }
          }
        })
      }
    }
    else if(this.state.radioSelected === 2){
      let data = []
      if(this.state.showMedian){
        data = this.state.dailyDataMedian
      }else{
        data = this.state.dailyData
      }
      if (data.length > 0) {
        data[this.state.sliderValue].deviceValues.map( d=>{ 
          if(level._id === d.levelId){ 
            if(d.value >= 0){
              Online = true
            }else{
              Online = false
            }
          }
        })
      }
    }
    return Online;
  }
  getValue = (level) => {
    let Value = false
    if(this.state.radioSelected === 1){
      let data = []
      if(this.state.showMedian){
        data = this.state.hourlyDataMedian
      }else{
        data = this.state.hourlyData
      }
      if (data.length > 0) {
        data[this.state.sliderValue].deviceValues.map( d=>{ 
          if(level._id === d.levelId){ 
              Value = d.value
          }
        })
      }
    }
    else if(this.state.radioSelected === 2){
      let data = []
      if(this.state.showMedian){
        data = this.state.dailyDataMedian
      }else{
        data = this.state.dailyData
      }
      if (data.length > 0) {
        data[this.state.sliderValue].deviceValues.map( d=>{ 
          if(level._id === d.levelId){ 
              Value = d.value
          }
        })
      }
    }
    return Value;
  }
  getTileUrl = function (tile, zoom) {
    return 'https://osm.airvisual.net/pm25_layer/10/'+ tile.x+'/'+tile.y+'.webp'
  };
mapInitialzation = () => {
  let google = this.props.google
  const map = new google.maps.Map(document.getElementById("myMap"), {
    zoom: this.props.zoom,
    center: this.props.location,
    mapTypeControl: false,
    streetViewControl: false,
  });
  // var layerOptions = {
  //   alt: 'MODIS_Terra_Aerosol',
  //   getTileUrl: this.getTileUrl,
  //   maxZoom: 10,
  //   minZoom: 2,
  //   name: 'MODIS_Terra_Aerosol',
  //   opacity: 0.7,
  // };
  // var imageMapType = new google.maps.ImageMapType(layerOptions);
  // map.overlayMapTypes.insertAt(0, imageMapType);
  this.map = map;
  this.setMapMarker(map)
}
median(values, fixed = 0) {
  if (values.length === 0) return 0;
  if(values.length === 2){
    return parseInt(((values[0] + values[1]) / 2).toFixed(0))
  }else{
    values.sort(function (a, b) {
        return a - b;
    });
    var half = Math.floor(values.length / 2);
    if (values.length % 2)
        return values[half];
    return parseInt(((values[half - 1] + values[half]) / 2).toFixed(fixed));
  }
}
// setMapMarker = (myMap = null) => {
//   let google = this.props.google
//   let map = this.map
//   if(myMap){
//     map = myMap
//   }
//   const infoWindow = new google.maps.InfoWindow({
//     content: "",
//     disableAutoPan: true,
//   });
//   const markers = this.props.aqiDevices.map((value, index) => {
//     if(value.level[0].metadata){
//       if(value.level[0].metadata.coordinates){
//         let position = value.level[0].metadata.coordinates
//         const marker = new google.maps.Marker({
//           position,
//           icon: {
//             url:
//               "data:image/svg+xml;charset=UTF-8;base64," +
//               btoa(this.setMarker(value.level[0])),
//               scaledSize: new this.props.google.maps.Size(this.getOnline(value.level[0])?45:27,
//               this.getOnline(value.level[0])?60:40)
//           },
//         });
//         marker.addListener("click", () => {
//           infoWindow.setContent(
//             '<div>'+
//               '<p style="font-family: arial; text-align: center; margin: 0;">'+
//                 '<b class="m-0">'+value.level[0].name+'</b><br/>'+
//                 '<small style="font-size:10">('+value.hub[0].name+')</small>'+
//               '</p>'+
//             '</div>'
//           );
//           infoWindow.open(map, marker);
//         });
//         return marker;
//       }
//     }
//   })
//   // const markers2 = this.props.indivAQI.map(aqiDevices => {
//   //   let position = null
//   //   let markerValue = []
//   //   aqiDevices.map((value, index) => {
//   //     if(value.level[0].metadata){
//   //       if(value.level[0].metadata.coordinates){
//   //         position = value.level[0].metadata.coordinates
//   //         markerValue.push(this.getValue(value.level[0]))
//   //       }
//   //     }
//   //   })
//   //   markerValue = this.median(markerValue)
//   //   const marker = new google.maps.Marker({
//   //     position,
//   //     icon: {
//   //       url:
//   //         "data:image/svg+xml;charset=UTF-8;base64," +
//   //         btoa(this.setMarker(null, markerValue)),
//   //         scaledSize: new this.props.google.maps.Size(markerValue >= 0 ?45:27,
//   //         markerValue >= 0 ?60:40)
//   //     },
//   //   });
//   //   marker.addListener("click", () => {
//   //     map.setCenter(position)
//   //     map.setZoom(11)
//   //   });
//   //   return marker;
//   // })
//   // if(map.getZoom() <= 10){
//   //   for (var i = 0; i < markers.length; i++) {
//   //     markers[i].setMap(null);
//   //   }
//   //   for (var i = 0; i < markers2.length; i++) {
//   //     markers2[i].setMap(map);
//   //   }
//   // }else{
//     for (var i = 0; i < markers.length; i++) {
//       markers[i].setMap(map);
//     }
//   //   for (var i = 0; i < markers2.length; i++) {
//   //     markers2[i].setMap(null);
//   //   }
//   // }
//   google.maps.event.addListener(map, 'zoom_changed', function() {
//     // if(map.getZoom() <= 10){
//     //   for (var i = 0; i < markers.length; i++) {
//     //     markers[i].setMap(null);
//     //   }
//     //   for (var i = 0; i < markers2.length; i++) {
//     //     markers2[i].setMap(map);
//     //   }
//     // }else{
//       for (var i = 0; i < markers.length; i++) {
//         markers[i].setMap(map);
//       }
//     //   for (var i = 0; i < markers2.length; i++) {
//     //     markers2[i].setMap(null);
//     //   }
//     // }
//   });
// }
onZoomFunc = (e,ee) => {
  let showMedian = this.state.showMedian;
  if(ee.zoom <= 10){
    showMedian = true
  }else{
    showMedian = false
  }
this.setState({showMedian})
}
  render() {
    if (this.state.loading) {
      return (
        <div style={{height: '100vh'}}>
          <Loader />
        </div>
      );
    } else {
      return (
        <div>
          <div className="row m-0">
            <div
              className="col-12 bg-dark text-light text-center"
              style={{ fontSize: "12px" }}
            >
              <b>
                {/* {this.state.radioSelected === 1 ? "Hourly" : "Daily"} */}
                Last Update:
              </b>{" "}
              {moment().format('L LTS')}
              {/* {func.convertToDate(
                this.state.hourlyData[this.state.changeDateIndex].created
              )} */}
            </div>
          </div>
          {/* <div id="myMap" style={{height: "calc(100vh - 100px)"}}></div> */}
          <div
            className="m-0 position-relative"
            style={{ height: "calc(100vh - 22px)" }}
          >
            <Map
              google={this.props.google}
              initialCenter={this.props.location}
              maxZoom={19}
              onClick={this.onMapClicked}
              zoom={this.props.zoom}
              onReady={this.fetchPlaces}
              onZoomChanged = {this.onZoomFunc}
              visible={this.state.visible}
              mapTypeControl={false}
              streetViewControl={false}
            >
              
              {console.log(this.state.showMedian)}
              {
              // this.state.showMedian? 
              // cities.map((city) => 
              // this.props.indivAQI[city].map((value, index)=> index === 0?(
              //   <Marker
              //     title={city}
              //     key={index}
              //     name={value.level[0].name}
              //     onClick={this.onMarkerClick}
              //     markerData={{name:value.level[0].name,location:value.hub[0].name}}
              //     position={cityLocations[city]}
              //     zIndex={
              //       this.getOnline(value.level[0])?1:0
              //     }
              //     icon={{
              //       url:
              //         "data:image/svg+xml;charset=UTF-8;base64," +
              //         btoa(this.setMarker(value.level[0])),
              //       scaledSize: new this.props.google.maps.Size(this.getOnline(value.level[0])?60:50,
              //         this.getOnline(value.level[0])?60:50)
              //     }}
              //   />
              // ):null))
              // :
              this.state.showMedian ?
              markers.map((value, index) =>
                  <Marker
                    title={value.name}
                    key={index}
                    name={value.name}
                    onClick={this.onMarkerClick}
                    markerData={{name:value.name}}
                    position={
                      value
                        ? value.coordinates
                        : null
                    }
                    zIndex={
                      value?1:0
                    }
                    icon={{
                      url:
                        "data:image/svg+xml;charset=UTF-8;base64," +
                        btoa(this.setMarker(value,value.value)),
                      scaledSize: new this.props.google.maps.Size(value?60:50,
                        value?60:50)
                    }}
                  />
              )
              : markers1.map((value, index) =>
                  <Marker
                    title={value.name}
                    key={index}
                    name={value.name}
                    onClick={this.onMarkerClick}
                    markerData={{name:value.name}}
                    position={
                      value
                        ? value.coordinates
                        : null
                    }
                    zIndex={
                      value?1:0
                    }
                    icon={{
                      url:
                        "data:image/svg+xml;charset=UTF-8;base64," +
                        btoa(this.setMarker(value,value.value)),
                      scaledSize: new this.props.google.maps.Size(value?60:50,
                        value?60:50)
                    }}
                  />
              )}
              {this.state.showMedian&&
                <Polyline
                  path={triangleCoords}
                  options={{
                      // strokeColor: "#F15A29",
                      strokeColor: "#000",
                      strokeOpacity: 1.0,
                      strokeWeight: 4,
                  }}
              />}
              
              {/* )} */}
              {/* {!this.state.showMedian? */}
                <InfoWindow
                  marker={this.state.activeMarker}
                  visible={this.state.showingInfoWindow}
                >
                  <div>
                    <p style={{fontFamily: "arial", textAlign: "center", margin: 0,}}>
                      <b className="m-0">{this.state.selectedPlace.markerData.name}</b><br/>
                      {markers.map((mark,key) => 
                      this.state.selectedPlace.markerData.name === mark.name &&
                        <small key={key} style={{fontSize:10}}>{mark.value}</small>
                      )}
                    </p>
                  </div>
                </InfoWindow>
              {/* :null} */}
            </Map>
          </div>
          <div className="d-flex justify-content-center position-absolute" style={{top: 25, left: 60, right: 60}}>
              <div className="text-center px-3 py-2" style={{backgroundColor: "rgba(0,0,0,0.7)", backdropFilter: "blur(20px)", borderRadius: 15}}>
                <span
                  className="mx-1"
                  style={{
                    backgroundColor: "#10ff00",
                    padding: "5px 10px",
                    fontSize: "12px",
                    fontWeight: "500",
                    color: "black",
                    borderRadius: "50px",
                    lineHeight: "30px",
                  }}
                >
                  Good
                </span>
                <span
                  className="mx-1"
                  style={{
                    backgroundColor: "#fff200",
                    padding: "5px 10px",
                    fontSize: "12px",
                    fontWeight: "500",
                    color: "black",
                    borderRadius: "50px",
                    lineHeight: "30px",
                  }}
                >
                  Moderate
                </span>
                <span
                  className="mx-1"
                  style={{
                    backgroundColor: "#ffae00",
                    padding: "5px 10px",
                    fontSize: "12px",
                    fontWeight: "500",
                    color: "black",
                    borderRadius: "50px",
                    lineHeight: "30px",
                  }}
                >
                  Unhealthy
                </span>
                <br className="d-md-none" />
                <span
                  className="mx-1"
                  style={{
                    backgroundColor: "#ff0000",
                    padding: "5px 10px",
                    fontSize: "12px",
                    fontWeight: "500",
                    color: "black",
                    borderRadius: "50px",
                    lineHeight: "30px",
                  }}
                >
                  Very Unhealthy
                </span>
                <span
                  className="mx-1"
                  style={{
                    backgroundColor: "#bf00ff",
                    padding: "5px 10px",
                    fontSize: "12px",
                    fontWeight: "500",
                    color: "black",
                    borderRadius: "50px",
                    lineHeight: "30px",
                  }}
                >
                  Hazardous
                </span>
              </div>
            </div>
          {/* <div className="px-lg-5 px-1 position-absolute" style={{bottom: 25, width: "calc(100% - 55px)"}}>
            <div className="row m-0 p-sm-2 px-sm-3 p-1 d-flex align-self-center" style={{backgroundColor: "rgba(255,255,255,0.8)", backdropFilter: "blur(20px)", borderRadius: 15}}>
              <div className="col-12 p-0">
                <div className="row m-0">
                  <div className="pr-3 align-self-center">
                    {this.state.radioSelected === 1 ? (
                      <div>
                        <b>Hour:</b>{" "}
                        {this.state.hourlyData[this.state.sliderValue]
                          ? func.convertToDate(
                              this.state.hourlyData[this.state.sliderValue]
                                .created,
                              "compTime"
                            )
                          : func.convertToDate(
                              this.state.hourlyData[29].created,
                              "comp"
                            )}
                      </div>
                    ) : (
                      <div>
                        <b>Day:</b>{" "}
                        {this.state.dailyData[this.state.sliderValue]
                          ? func.convertToDate(
                              this.state.dailyData[this.state.sliderValue]
                                .created,
                              "compDate"
                            )
                          : func.convertToDate(
                              this.state.dailyData[14].created,
                              "compDate"
                            )}
                      </div>
                    )}
                  </div>
                  <div className="col-md col-12 p-0">
                    <RangeSlider
                      value={parseInt(this.state.sliderValue)}
                      className="py-lg-2 py-0"
                      onChange={(changeEvent) => {
                        let propDate =
                          this.state.hourlyData[changeEvent.target.value]
                            .created;
                        let currDate = moment().format(
                          "YYYY-MM-DD[T]HH:mm:ss"
                        );

                        let sliderIndex = true;
                        if (propDate < currDate) {
                          sliderIndex = false;
                        } else {
                          sliderIndex = true;
                        }

                        this.setState({
                          sliderValue: changeEvent.target.value,
                          sliderIndex,
                        });
                      }}
                      min={0}
                      max={this.state.radioSelected === 1 ? 29 : 14}
                      tooltip="off"
                      variant={this.state.sliderIndex ? "danger" : "primary"}
                    />
                  </div>
                </div>
              </div>
              <div className="col-6 p-0 d-flex align-items-center" style={{ height: "30px", gap: "5px" }}>
                  <img
                    height="30px"
                    width={50}
                    src={Slider_rwd}
                    onClick={() => this.handleRwd()}
                  />
                  {this.state.playType === "Stop" ? (
                    <img
                      height="30px"
                      width={50}
                      src={Slider_play}
                      onClick={() => this.handlePlay()}
                    />
                  ) : (
                    <img
                      height="30px"
                      width={50}
                      src={Slider_pause}
                      onClick={() => this.handlePlay()}
                    />
                  )}
                  <img
                    height="30px"
                    width={50}
                    src={Slider_fwd}
                    onClick={() => this.handleFwd()}
                  />
              </div>
              <div className="col-6 p-0 d-flex justify-content-end">
                <ButtonToolbar aria-label="Toolbar with button groups">
                  <ButtonGroup
                    className="float-right"
                    aria-label="First group"
                  >
                    <Button
                      style={{
                        padding: "0.275rem 0.75rem",
                        fontSize: "15px",
                        height: "30px",
                      }}
                      color="outline-secondary"
                      onClick={() => this.onRadioBtnClick(1)}
                      active={this.state.radioSelected === 1}
                    >
                      Hourly
                    </Button>
                    <Button
                      style={{
                        padding: "0.275rem 0.75rem",
                        fontSize: "15px",
                        height: "30px",
                      }}
                      color="outline-secondary"
                      onClick={() => this.onRadioBtnClick(2)}
                      active={this.state.radioSelected === 2}
                    >
                      Daily
                    </Button>
                  </ButtonGroup>
                </ButtonToolbar>
              </div>
            </div>
          </div> */}
          
        </div>

      );
    }
  }
}
export default GoogleApiWrapper({
  apiKey: "AIzaSyA9osJ2QBSR6f9oBEZULavTAJzZxVccQMo",
})(OverviewMap);
