import React from "react";
import { Link } from "react-router-dom";

// Images

import logo from "../../images/logo.png";
import AQImage from "../../images/icons/airquality.png";
import WaterImage from "../../images/icons/water.png";
import CCImage from "../../images/icons/retail.png";
import BankImage from "../../images/icons/bank.png";
import PowerImage from "../../images/icons/power.png";
import AgriImage from "../../images/icons/agriculture.png";
import OfficeImage from "../../images/icons/office.png";
import DCImage from "../../images/icons/datacenter.png";
import SphereImage from "../../images/icons/city.png";
import HomeImage from "../../images/icons/home.png";

// Tab

import HeaderTab from "./tab";

const Header = () => {
  return (
    <header className="header_area dropshadow position-fixed d-flex align-items-center" style={{backgroundColor: "rgba(0,81,145,0.8)",top: 0, backdropFilter: "blur(20px)"}}>
      <a
        className="navbar-brand logo_hv bg-white"
        href="https://www.linked-things.com"
      >
        <img
          src={logo}
          style={{ height: "55px", margin: "9px" }}
          alt="logo"
        />
      </a>
      <div className="col h3 d-lg-inline d-none m-0 text-center" style={{color: "white"}}>Largest Air Quality Monitoring Network in Pakistan</div>
      <div className="col h4 d-lg-none d-md-inline d-none m-0 text-center" style={{color: "white"}}>Largest Air Quality Monitoring Network in Pakistan</div>
      <div className="col h5 m-0 d-md-none d-inline text-center" style={{color: "white"}}>Largest Air Quality Monitoring Network in Pakistan</div>
    </header>
  );
};

export default Header;
